import loginStyle from './Login.module.css';

import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';

import { emailValidator, loginBtnValidator, passwordValidator } from '../../util/validators';
import * as userApi from '../../../services/user';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';

export const Login = () => {
    const { t } = useTranslation();
    const { userLogin } = useContext(UserContext);
    const navigate = useNavigate();
    const [values, setValues] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        userErr: '',
        passErr: '',
    })

    const changeHandler = (e) => {
        setValues(state => ({
            ...state,
            [e.target.name]: e.target.value
        }));
    };

    const errorCheck = (e) => {
        if (e.target.name === 'email') {
            setErrors(errors => ({
                ...errors,
                userErr: emailValidator(values.email),
            }))
        } else if (e.target.name === 'password') {
            setErrors(errors => ({
                ...errors,
                passErr: passwordValidator(values.password),
            }))
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const {
            email,
            password,
        } = Object.fromEntries(new FormData(e.target));

        try {
            userApi.login(email, password)
                .then(user => {
                    userLogin(user)
                    navigate('/')
                })

        } catch (err) {
            alert(err.message)
        }
    }

    return (
        <form className={loginStyle['login-form']} onSubmit={onSubmit}>
            <h3>{t('Login.h')}</h3>
            <label htmlFor="email">{t('Login.email')}</label>
            <input
                type="text"
                id="email"
                name="email"
                onChange={changeHandler}
                value={values.email}
                onBlur={errorCheck}
                placeholder={t('Login.email')}
            />
            {errors.userErr}

            <label htmlFor="password">{t('Login.pass')}</label>
            <input
                type="password"
                id="password"
                name="password"
                onChange={changeHandler}
                value={values.password}
                onBlur={errorCheck}
                placeholder={t('Login.pass')}
            />
            {errors.passErr}

            <input
                className={loginStyle['login-btn']}
                type="submit"
                value={t('Login.btn')}
                disabled={loginBtnValidator(values)}
            />
        </form>
    );
};