import btnsStyle from './LangPicker.module.css';


import { useTranslation } from "react-i18next";



export const LangPicker = () => {
    const { i18n } = useTranslation();

    const clickHandler = (lang) => {
        i18n.changeLanguage(lang);
    }

    return (
        <div className={btnsStyle['lang']}>
            <button onClick={() => clickHandler('en')}>English</button>
            <button onClick={() => clickHandler('gr')}>ελληνικός</button>
            <button onClick={() => clickHandler('bg')}>Български</button>
        </div>
    )
}