import registerStyles from './Register.module.css';
import * as userApi from '../../../services/user';

import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';

import { UserContext } from '../../context/UserContext';
import { emailValidator, firstNameValid, lastNameValid, passwordValidator, registerBtnValidator, repassValidator } from '../../util/validators';
import { useTranslation } from 'react-i18next';


export const Register = () => {
    const {t} = useTranslation();
    const { userLogin } = useContext(UserContext);
    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        userErr: '',
        passErr: '',
        rePassErr: '',
    });
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        rePassword: '',
        referral: ''
    });

    const changeHandler = (e) => {
        setValues(state => ({
            ...state,
            [e.target.name]: e.target.value
        }));
    };

    const errorCheck = (e) => {
        if (e.target.name === 'email') {
            setErrors(errors => ({
                ...errors,
                userErr: emailValidator(values.email),
            }))
        } else if (e.target.name === 'password') {
            setErrors(errors => ({
                ...errors,
                passErr: passwordValidator(values.password),
            }))
        } else if (e.target.name === 'rePassword') {
            setErrors(errors => ({
                ...errors,
                rePassErr: repassValidator(values),
            }))
        } else if (e.target.name === 'firstName') {
            setErrors(errors => ({
                ...errors,
                firstName: firstNameValid(values.firstName),
            }))
        } else if (e.target.name === 'lastName') {
            setErrors(errors => ({
                ...errors,
                lastName: lastNameValid(values.lastName),
            }))
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const {
            firstName,
            lastName,
            email,
            password,
            referral
        } = Object.fromEntries(new FormData(e.target));


        try {
            userApi.register(firstName, lastName, email, password, referral)
                .then(user => {
                    userLogin(user)
                    navigate('/')
                })

        } catch (err) {
            alert(err.message)
        }
    };
    return (
        <form className={registerStyles['register-form']} onSubmit={onSubmit}>
            <h3>{t('Register.h')}</h3>
            <label htmlFor="firstName">{t('Register.fName')}</label>
            <input
                type="text"
                id="firstName"
                name="firstName"
                onChange={changeHandler}
                value={values.firstName}
                onBlur={errorCheck}
                placeholder={t('Register.fName')}
            />
            {errors.firstName}

            <label htmlFor="lastName">{t('Register.lName')}</label>
            <input
                type="text"
                id="lastName"
                name="lastName"
                onChange={changeHandler}
                value={values.lastName}
                onBlur={errorCheck}
                placeholder={t('Register.lName')}
            />
            {errors.lastName}

            <label htmlFor="email">{t('Register.email')}</label>
            <input
                type="text"
                id="email"
                name="email"
                onChange={changeHandler}
                value={values.email}
                onBlur={errorCheck}
                placeholder={t('Register.email')}
            />
            {errors.userErr}

            <label htmlFor="password">{t('Register.pass')}</label>
            <input
                type="password"
                id="password"
                name="password"
                onChange={changeHandler}
                value={values.password}
                onBlur={errorCheck}
                placeholder={t('Register.pass')}
            />
            {errors.passErr}

            <label htmlFor="rePassword">{t('Register.rRass')}</label>
            <input
                type="password"
                id="rePassword"
                name="rePassword"
                onChange={changeHandler}
                value={values.rePassword}
                onBlur={errorCheck}
                placeholder={t('Register.rRass')}
            />
            {errors.rePassErr}

            <label htmlFor="referral">{t('Register.ref')}</label>
            <input
                type="text"
                id="referral"
                name="referral"
                onChange={changeHandler}
                value={values.referral}
                onBlur={errorCheck}
                placeholder={t('Register.ref')}
            />

            <input
                className={registerStyles['register-btn']}
                type="submit"
                value={t('Register.btn')}
                disabled={registerBtnValidator(values)}
            />
        </form>
    )
}