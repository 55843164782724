const EMAIL_PATTERN = /^([a-zA-Z]+)@([a-zA-Z]+)\.([a-zA-Z]+)$/;
export const emailValidator = (email) => {

    if (!EMAIL_PATTERN.test(email)) {
        return (
            <div style={{ color: 'red' }}>Email is not valid</div>
        );
    } else {
        return '';
    }
}

export const firstNameValid = (firstName) => {
    if (firstName.length < 3) {
        return (
            <div style={{ color: 'red' }}>Field is requred!</div>
        );
    } else {
        return '';
    }
}

export const lastNameValid = (lastName) => {
    if (lastName.length < 3) {
        return (
            <div style={{ color: 'red' }}>Field is requred!</div>
        );
    } else {
        return '';
    }
}

export const passwordValidator = (password) => {
    if (password.length < 8) {
        return (
            <div style={{ color: 'red' }}>Password must be minimum 8 characters long!</div>
        );
    } else {
        return '';
    }
}

export const repassValidator = (values) => {
    if (values.rePassword.length < 8) {
        return (
            <div style={{ color: 'red' }}>Repeat password must be minimum 8 characters long!</div>
        );
    } else if (values.rePassword !== values.password) {
        return (
            <div style={{ color: 'red' }}>Passwords don't match</div>
        );
    } else {
        return '';
    }
}

export const loginBtnValidator = (values) => {
    if (!EMAIL_PATTERN.test(values.email) || values.password.length < 8) {
        return true;
    } else {
        return false;
    }
}

export const registerBtnValidator = (values) => {
    if (!EMAIL_PATTERN.test(values.email)
        || values.password.length < 8
        || values.rePassword.length < 8
        || values.rePassword !== values.password) {
        return true;
    } else {
        return false;
    }
}
