import { Link } from 'react-router-dom';
import groupStyle from './Groups.module.css';
import { useTranslation } from 'react-i18next';


export const Groups = () => {
    const {t} = useTranslation();

    return (
        <>
            <h2 className={groupStyle['group-h2']}>{t('Services.common.c2')}</h2>
            <section className={groupStyle['main']}>

                <section className={groupStyle['main-first']}>
                    <h3>MGLD Pro Pack</h3>
                        <p>{t('Services.pro.p1')}</p>
                        <p>{t('Services.pro.p2')}</p>
                        <p>{t('Services.pro.p3')}</p>
                        <p>{t('Services.pro.p4')}</p>
                        <p>{t('Services.pro.p5')}</p>
                    <Link to='https://t.me/MGLDOfficial' target='blank'>{t('Services.common.c1')}</Link>
                </section>

                <section className={groupStyle['main-second']}>
                <h3>MGLD Premium Member PROMO</h3>
                    <div>
                        <p>{t('Services.premium.p1')}</p>
                        <p>{t('Services.premium.p2')}</p>
                        <p>{t('Services.premium.p3')}</p>
                        <p>{t('Services.premium.p4')}</p>
                        <p>{t('Services.premium.p5')}</p>
                        <p>{t('Services.premium.p6')}</p>
                        <p>{t('Services.premium.p7')}</p>
                        <p>{t('Services.premium.p8')}</p>
                        <p>{t('Services.premium.p10')}</p>
                        <p>{t('Services.premium.p9')}</p>
                    </div>
                    <Link to='https://t.me/CryptoTraderMegalodon' target='blank'>{t('Services.common.c1')}</Link>
                </section>

            </section>
        </>
    );
};