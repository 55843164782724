import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import * as userApi from '../../services/user';

import { UserContext } from '../context/UserContext';

export const Logout = () => {
    const {userLogout} = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        userApi.logout()
            .then(res => {
                userLogout();
                navigate('/');
            })
            .catch(() => {
                navigate('/');
            });
    },[]);

    return null;
}