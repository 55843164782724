import { clearUserData, setUserData } from "../components/util/localStorage";

const host = 'https://mgldprotradyng.onrender.com';

async function request(url, options) {
    try {
        const response = await fetch(url, options);

        if (response.ok === false) {
            if (response.status === 401) {
                sessionStorage.removeItem('email');
                sessionStorage.removeItem('authToken');
                sessionStorage.removeItem('userId');
            }
            const error = await response.json();
            throw new Error(error.message);
        }

        try {
            const data = await response.json();
            return data;
        } catch (err) {
            return response;
        }
    } catch (err) {
        alert(err.message);
        throw err;
    }
}

function getOptions(method = 'get', body) {
    const options = {
        method,
        headers: {}
    };

    const token = sessionStorage.getItem('authToken');
    if (token != null) {
        options.headers['X-Authorization'] = token;
    }

    if (body) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(body);
    }

    return options;
}

export async function get(url) {
    return await request(url, getOptions());
}

export async function post(url, data) {
    return await request(url, getOptions('post', data));
}

export async function put(url, data) {
    return await request(url, getOptions('put', data));
}

export async function del(url) {
    return await request(url, getOptions('delete'));
}

export async function login(email, password) {
    const result = await post(`${host}/users/login`, { email, password });

    sessionStorage.setItem('email', result.email);
    sessionStorage.setItem('authToken', result.accessToken);
    sessionStorage.setItem('userId', result._id);
    sessionStorage.setItem('firstName', result.firstName);
    sessionStorage.setItem('lastName', result.lastName);
    sessionStorage.setItem('referral', result.referral);
    sessionStorage.setItem('referrals', result.referrals);
    setUserData(result);

    return result;
}

export async function register(firstName, lastName, email, password, referral) {
    const result = await post(`${host}/users/register`, { firstName, lastName, email, password, referral });

    sessionStorage.setItem('email', result.email);
    sessionStorage.setItem('authToken', result.accessToken);
    sessionStorage.setItem('userId', result._id);
    sessionStorage.setItem('firstName', result.firstName);
    sessionStorage.setItem('lastName', result.lastName);
    sessionStorage.setItem('referral', result.referral);
    sessionStorage.setItem('referrals', result.referrals);
    setUserData(result);

    return result;
}

export async function logout() {
    const result = await get(`${host}/users/logout`);

    sessionStorage.removeItem('email');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('firstName');
    sessionStorage.removeItem('lastName');
    sessionStorage.removeItem('referral');
    sessionStorage.removeItem('referrals');
    clearUserData();

    return result;
}