import { useTranslation } from 'react-i18next';
import accountStyle from './Account.module.css';

import { Login } from './login/Login';
import { Register } from './register/Register';


export const Account = () => {
    const {t} = useTranslation();

    return (
        <section className={accountStyle['log-reg']}>
            <h2>{t('Log-Reg.h1')}</h2>
            <div>
            <section className={accountStyle['log']}>
            <Login/>
            </section>

            <section className={accountStyle['reg']}>
            <Register/>
            </section>
            </div>
        </section>
    )
}