import './App.css';
import { Route, Routes } from 'react-router-dom';

import { Header } from "./components/header/Header";
import { Home } from './components/home/Home';
import { Account } from './components/account/Account';
import { Logout } from './components/logout/Logout';
import { UserProvider } from './components/context/UserContext';
import { Groups } from './components/groups/Groups';
import { useEffect } from 'react';


function App() {

  console.log("app is live");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userData'));

    if (user) {
      sessionStorage.setItem('email', user.email);
      sessionStorage.setItem('authToken', user.accessToken);
      sessionStorage.setItem('userId', user._id);
      sessionStorage.setItem('firstName', user.firstName);
      sessionStorage.setItem('lastName', user.lastName);
      sessionStorage.setItem('referral', user.referral);
      sessionStorage.setItem('referrals', user.referrals);
    }
  }, [])

  return (
    <UserProvider>
      <div className="App">

        <Header />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/account' element={<Account />} />
          <Route path='/services' element={<Groups />} />
          <Route path='/logout' element={<Logout />} />
        </Routes>

      </div>
    </UserProvider>
  );
}

export default App;
