import { Link } from 'react-router-dom';

import headerStyle from '../header/Header.module.css';

import { UserContext } from '../context/UserContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LangPicker } from '../shared/LangPicker';

export const Header = () => {
    const { t } = useTranslation();
    const { userData } = useContext(UserContext);

    return (
        <>
            <LangPicker />
            <p className={headerStyle['top-p']}> <i class="fa-solid fa-gift"></i>{t('Header.promo')}<Link to="https://t.me/MGLDOfficial" target="_blank"> {t('Header.learn')}</Link></p>

            <div className={headerStyle['nav-bar']}>
                <img src='mgld.jpg' alt='mgld' />
                <ul>
                    {userData != undefined
                        ? <>
                            <li><Link to="/">{t('Header.home')}</Link></li>
                            <li><Link to="/services">{t('Header.services')}</Link></li>
                            <li><Link to="/logout">{t('Header.logout')}</Link></li>
                        </>
                        : <>
                            <li><Link to="/">{t('Header.home')}</Link></li>
                            <li><Link to="/account">{t('Header.account')}</Link></li>
                            <li><Link to="/services">{t('Header.services')}</Link></li>
                        </>
                    }
                </ul>
                <Link to='https://t.me/MGLDOfficial' target="_blank" className={headerStyle['tel-icon']}>{t('Header.telegram')}<i class="fa-brands fa-telegram"></i></Link>
            </div>
        </>
    );
};