import { useTranslation } from 'react-i18next';
import homeStyle from './Home.module.css';

import { Link } from "react-router-dom";

export const Home = () => {
    const { t } = useTranslation();

    return (
        <>
            <img className={homeStyle['big-phone']} src='phone.png' alt='phone' />
            <img className={homeStyle['small-phone']} src='phone.png' alt='phone' />
            <img className={homeStyle['cont-big-phone']} src='big.png' alt='big-phone' />
            <img className={homeStyle['cont-small-phone']} src='small.png' alt='small-phone' />
            <section className={homeStyle['head-sec']}>
                <h1>{t('Home.Head.h1')}</h1>
                <h2 className={homeStyle['head-sec-h2']}>{t('Home.Head.h2')}</h2>
                <div>
                    <Link to='https://t.me/MGLDOfficial' target="_blank">{t('Home.Head.btn1')}</Link>
                    <Link to='/services'>{t('Home.Head.btn2')}</Link>
                </div>
            </section>

            <section className={homeStyle['main-sec']}>
                <div className={homeStyle['pic-main-sec']}>
                    <img src='market.jpg' alt='market' />
                </div>
                <div className={homeStyle['cont-main-sec']}>
                    <h2>{t('Home.Main.h2')}</h2>
                    <p>{t('Home.Main.p1')}</p>
                    <p>{t('Home.Main.p2')}</p>
                </div>
            </section>

            <section className={homeStyle['intro-sec']}>
                <img src='mgld.jpg' alt='logo' />
                <h2>{t('Home.Intro.h2')}</h2>
                <p>{t('Home.Intro.p')}</p>
                <Link to='/services'>{t('Home.Intro.btn')}</Link>
            </section>

            <section className={homeStyle['advan']}>
                <h2>{t('Home.Advan.h2')}</h2>
                <div className={homeStyle['advan-main-div']}>
                    <div>
                        <i class="fa-solid fa-wave-square"></i>
                        <p>{t('Home.Advan.p1')}</p>
                    </div>

                    <div>
                        <i class="fa-solid fa-wave-square"></i>
                        <p>{t('Home.Advan.p2')}</p>
                    </div>

                    <div>
                        <i class="fa-brands fa-telegram"></i>
                        <p>{t('Home.Advan.p3')}</p>
                    </div>

                    <div>
                        <i class="fa-solid fa-shield"></i>
                        <p>{t('Home.Advan.p4')}</p>
                    </div>

                    <div>
                        <i class="fa-solid fa-user-graduate"></i>
                        <p>{t('Home.Advan.p5')}</p>
                    </div>

                    <div>
                        <i class="fa-solid fa-chess"></i>
                        <p>{t('Home.Advan.p6')}</p>
                    </div>

                    <div>
                        <i class="fa-solid fa-route"></i>
                        <p>{t('Home.Advan.p7')}</p>
                    </div>

                    <div>
                        <i class="fa-solid fa-water-ladder"></i>
                        <p>{t('Home.Advan.p8')}</p>
                    </div>

                    <div>
                        <i class="fa-solid fa-lightbulb"></i>
                        <p>{t('Home.Advan.p9')}</p>
                    </div>
                </div>
            </section>

            <section className={homeStyle['telegram']}>
                <h2>{t('Home.Telegram.h2')}</h2>
                <span>{t('Home.Telegram.span')}</span>
                <p>{t('Home.Telegram.p')}</p>
            </section>

            <section className={homeStyle['risk-mng']}>
                <img src='teleg.png' alt='telegram' />
                <div>
                    <h2>{t('Home.Risk.h2')}</h2>
                    <p>{t('Home.Risk.p')}</p>
                    <span>{t('Home.Risk.span')}</span>
                </div>
                <div>

                </div>
                <img className={homeStyle['telegram-phone']} src='phone.png' alt='phone' />
                <img className={homeStyle['telegram-phone-cont']} src='manag.png' alt='phone' />
            </section>

            <section className={homeStyle['final-sec']}>
                <h2>{t('Home.Final.h2')} <span>{t('Home.Final.span')}</span></h2>
                <div>{t('Home.Final.div')}</div>
                <p>{t('Home.Final.p')}</p>
                <Link to='https://t.me/MGLDOfficial' target='blank'>{t('Home.Final.btn')}</Link>
            </section>

            <section className={homeStyle['social']}>
                <div className={homeStyle['social-div']}>
                    <div>
                        <Link to='https://www.facebook.com/mgldcapital' target='blank'>
                            <i class="fa-brands fa-facebook"></i>
                        </Link>
                        <p>Facebook</p>
                    </div>

                    <div>
                        <Link to='https://www.instagram.com/mgld.capital/?igshid=YmMyMTA2M2Y%3D' target='blank'>
                            <i class="fa-brands fa-instagram"></i>
                        </Link>
                        <p>Instagram</p>
                    </div>

                    <div>
                        <Link to='https://t.me/MGLDOfficial' target='blank'>
                            <i class="fa-brands fa-telegram"></i>
                        </Link>
                        <p>Telegram</p>
                    </div>

                    <div>
                        <Link to='https://www.youtube.com/@mgldcapital7557' target='blank'>
                            <i class="fa-brands fa-youtube"></i>
                        </Link>
                        <p>YouTube</p>
                    </div>
                </div>
            </section>
        </>
    );
};